import React from 'react';
import {
    Container,
    TextField,
    Grid,
    Typography,
    Button,
    Paper, Divider, CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import moment from 'moment';

import {
    LineChart,
    XAxis,
    Tooltip,
    CartesianGrid,
    Line,
    ResponsiveContainer,
} from 'recharts';
import { match } from 'assert';

const chartHeight = 170;

const qs = require('querystring');

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    chartHolder: {
        background: '#fafafa',
        height: chartHeight,
        padding: theme.spacing(3, 2, 0, 2),
        borderRadius: 10,
        boxShadow: "0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #eee",
    },
    chartSection: {
        padding: "100px 0",
        minHeight: 300
    }
});

class UserStatus extends React.Component {
    constructor(props) {
        super(props);

        const username = props.match.params.username;
        this.loadData(username);

        this.state = {
            username,
            loading: true,
            userData: null
        }

        this.chart1 = React.createRef()
    }

    loadData = async (username) => {
        const matchingDocs = await firebase.firestore()
            .collection('tracking-directory')
            .doc('tracked-users')
            .collection('users')
            .where('lusername', '==', username.toLowerCase()).get();

        if (matchingDocs.docs.length === 0) {
            console.log("USER NOT FOUND");
            this.setState({
                loading: false,
                username: username,
                error: "User " + username + " either is not being tracked or does not exist."
            });
        } else {
            this.setState({
                loading: false,
                username: username,
                userData: matchingDocs.docs[0].data()
            });
        }
    }

    getDateArray = function (start, end) {
        var arr = new Array();
        var dt = new Date(start);
        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }
        arr.push(new Date(dt));
        return arr;
    }

    /**
     * transform userData which looks like this:
     * {
     *  dates: [firebase timestamp],
     *  followers: [string number],
     *  following: [string number]
     * }
     * to something that looks like this:
     * [
     *  {name: datestr, followers: number, following: number}
     * ]
     */
    getData = () => {
        let exitData = [];
        const data = this.state.userData;
        console.log(data);
        const minDate = Math.min.apply(null, data.dates.map(date => date.toDate()));
        const maxDate = Math.max.apply(null, data.dates.map(date => date.toDate()));
        const dateArray = this.getDateArray(minDate, maxDate).map(date => moment(date));
        let dateidx = 0;
        for (let i = 0; i < data.dates.length; i++) {
            // console.log(i)
            while (dateidx < dateArray.length && !dateArray[dateidx].isSame(data.dates[i].toDate(), 'day')) {
                console.log(dateidx)
                exitData.push({
                    name: dateArray[dateidx].format('MM-DD-YYYY'),
                    followers: null,
                    following: null
                })
                dateidx++;
                console.log(dateArray[dateidx])
            }
            exitData.push({
                name: moment(data.dates[i].toDate()).format('MM-DD-YYYY'),
                followers: data.followers[i],
                following: data.following[i]
            });
            dateidx++;
        }
        return exitData;
    }

    formatXAxis(tickItem) {
        // If using moment.js
        console.log("here", tickItem)
        console.log(moment(tickItem).format('MM-DD-YY'))
        return moment(tickItem).format('MM-DD-YY')
    }

    generateTweetBasedOnUser = async () => {
        //generating tweet
        //Fetching last 15 tweets for **[[USER]]**
        //filtering by the 4 most liked tweets out of the last 15
        //Feeding 4 tweets to GPT-3 and generating similar tweet
    }

    render() {
        const { classes } = this.props;

        if (this.state.loading) {
            return <div className={classes.root}>
                <Container maxWidth='md'>
                    <Grid container spacing={3} className={classes.chartSection} justify="center">
                        <CircularProgress />
                    </Grid>
                </Container>
            </div>
        } else if (!this.state.userData) {

        } else {
            return <div className={classes.root}>
                <Container maxWidth='md'>
                    <Grid container spacing={3} className={classes.chartSection}>
                        <Grid xs="12">
                            <Typography variant="h6" paragraph>
                                Viewing historic data for @{this.state.username}
                            </Typography>
                        </Grid>
                        <Grid item xs="12" sm="6" md="6" >
                            <Typography varaint="body1" align="left" style={{ color: 'gray' }}>Following</Typography>
                            <div className={classes.chartHolder}>
                                <ResponsiveContainer width="99%" >
                                    <LineChart
                                        // width={400}
                                        // className={classes.followingChart}
                                        height={chartHeight}
                                        data={this.getData()}
                                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                                    >
                                        <XAxis dataKey="name" tick={false} tickFormatter={this.formatXAxis} />
                                        <Tooltip />
                                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                                        <Line connectNulls type="monotone" dataKey="following" stroke="#ff7300" yAxisId={0} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </Grid>
                        <Grid item xs="12" sm="6" md="6" ref={this.chart1}>
                            <Typography varaint="body1" align="left" style={{ color: 'gray' }}>Followers</Typography>

                            <div className={classes.chartHolder}>

                                <ResponsiveContainer width="99%" >
                                    <LineChart
                                        data={this.getData()}
                                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                                    >
                                        <XAxis dataKey="name" tick={false} tickFormatter={this.formatXAxis} />
                                        <Tooltip />
                                        {/* <CartesianGrid stroke="#f5f5f5" /> */}
                                        <Line connectNulls type="monotone" dataKey="followers" stroke="#387908" yAxisId={1} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="sm">
                    <Typography variant="body1"><a href="/">Back to home</a></Typography>
                </Container>
            </div>
        }
    }
}

export default withRouter(withStyles(styles)(UserStatus));