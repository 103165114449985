import React from 'react';
import {
    Container,
    TextField,
    Grid,
    Typography,
    Button,
    Paper, Divider, CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import TwitterIcon from '@material-ui/icons/Twitter';
import axios from 'axios';
import config from '../config';
import SearchIcon from '@material-ui/icons/Search';
import Counter from './Counter';

const qs = require('querystring');

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        background: "#ECF0F3"
    },
    margin: {
        margin: theme.spacing(3,0)
    }
});

class Subscribe extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: ""
        }
    }


    render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <Container maxWidth="sm">
                <Grid container spacing={3} className={classes.margin}>
                    <Grid item xs={12}>
                        <Typography variant="h3" align="left">
                            Still in Development
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    }
}

export default withRouter(withStyles(styles)(Subscribe));