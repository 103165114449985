import React from 'react';
import {
    Container,
    TextField,
    Grid,
    Typography,
    Button,
    Paper, Divider, CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const qs = require('querystring');

const styles = theme => ({
    root: {
        background:'#EFF4F8',
    //    boxShadow: "0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #eee",
    boxShadow: "16px 16px 30px 0px #D1D9E6, -16px -16px 30px 0px #FFF",
       borderRadius: 25
    },
});

/**
 * 
 */
class Counter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: 0,
            loading: true
        }

        this.loadData(props.dbPath, props.docField);
    }

    loadData = async (dbPath, docField) => {
        const doc = await firebase.firestore().doc(dbPath).get();
        const data = doc.data()[docField];

        this.setState({
            data,
            loading: false
        });
    }

    render() {
        const { classes } = this.props;

        return <div className={classes.root} style={{height: this.props.height, maxWidth: this.props.maxWidth}}>
            {/* <Typography variant="h3">somoto.io</Typography>
            <Typography variant="h3">oimo.io</Typography>
            <Typography variant="h3">zopya.io</Typography>
            <Typography variant="h3">founty.io</Typography>
            <Typography variant="h3">xioro.io</Typography>
            <Typography variant="h3">usano.io/usona.io</Typography>
            <Typography variant="h3">ilo.so</Typography>
            <Typography variant="h3">aziv.io</Typography> */}
            <Grid container justify="center" style={{height: '100%'}} alignContent="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                <Typography variant="body1" paragraph style={{marginTop: 8, color: 'gray'}}>{this.props.overText}</Typography>
                </Grid>
               
                {this.state.loading ? 
                <CircularProgress />
                :
                <Typography variant="h1">{this.state.data}</Typography>
                }
            </Grid>
        </div>
    }
}

export default withRouter(withStyles(styles)(Counter));