import React from 'react';
import {
    Container,
    TextField,
    Grid,
    Typography,
    Button,
    Paper, Divider, CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import TwitterIcon from '@material-ui/icons/Twitter';
import axios from 'axios';
import config from '../config';
import SearchIcon from '@material-ui/icons/Search';
import Counter from './Counter';

const qs = require('querystring');

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: "#ECF0F3"
    },
    searchContainer: {
        margin: '100px 0'
    },
    searchInput: {
        borderRadius: 50,
        boxShadow: "16px 16px 30px 0px #D1D9E6, -16px -16px 30px 0px #FFF",
        marginTop: 24,
        [`& fieldset`]: {
            borderRadius: 50,
        },
    }
});

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: ""
        }
    }

    handleSearchClick = (e) => {
        e.preventDefault();

        this.props.history.push(`/user/${this.state.username}`);
    }

    handleChange = (e) => {
        this.setState({
            [e.currentTarget.id]: e.currentTarget.value
        });
    }

    render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            {/* <Typography variant="h3">somoto.io</Typography>
            <Typography variant="h3">oimo.io</Typography>
            <Typography variant="h3">zopya.io</Typography>
            <Typography variant="h3">founty.io</Typography>
            <Typography variant="h3">xioro.io</Typography>
            <Typography variant="h3">usano.io/usona.io</Typography>
            <Typography variant="h3">ilo.so</Typography>
            <Typography variant="h3">aziv.io</Typography> */}
            <Container maxWidth="sm">
                <Grid container className={classes.searchContainer} component='form' onSubmit={this.handleSearchClick}>
                    <Grid item xs="12">
                        <Typography variant="h4" paragraph>
                            Search to get historic insights
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Enter a twitter username to see the account insights
                        </Typography>
                        <TextField
                            id='username'
                            value={this.state.username}
                            onChange={this.handleChange}
                            variant="outlined"
                            size="small"
                            className={classes.searchInput}
                            InputProps={{
                                style: {
                                    paddingLeft: 8
                                },
                                endAdornment: <div style={{ cursor: "pointer" }} onClick={this.handleSearchClick}><SearchIcon /></div>
                            }}
                        />

                    </Grid>
                </Grid>
                
                <Grid container className={classes.statsContainer} spacing={3} justify="center">
                    <Grid item xs={12} sm={6} align='center'>
                        <Counter dbPath="stats/usage" docField="users" maxWidth={300} height={200} overText="Tracked Users" />
                    </Grid>
                </Grid>
                <Grid container maxWidth="sm" spacing={3} justify="center">
                    <Grid item xs={12} sm={6} align='center'>
                        <Typography variant="body1">Disclaimer: this tracker tracks the people <a href="https://twitter.com/nikita_jerschow">@nikita_jerschow</a> is following, if you want to start tracking someone else, you can submit a request <a href="mailto:nikita.jerschow@gmail.com?subject=track new twitter user&body=add username of the person you want to start tracking below this line.">here</a></Typography>
                    </Grid>
                </Grid>
                <Grid container maxWidth="sm" spacing={3} justify="center">
                    <Grid item xs={12} sm={6} align='center'>
                        <Typography variant="body1">Feature requests <a href="mailto:nikita.jerschow@gmail.com?subject=feature request&body=Describe your feature request below this line">here</a></Typography>
                    </Grid>
                </Grid>
                {/* <iframe id="iframe" src="https://simplelifedata.com" width="100%" height="400" allowfullscreen sandbox></iframe> */}
                {/* <embed src="http://simplelifdata.com" width={500} height={500} /> */}
            </Container>
        </div>
    }
}

export default withRouter(withStyles(styles)(Dashboard));