import React from 'react';
import {
    Container,
    TextField,
    Grid,
    Typography,
    Button,
    Paper, Divider, CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const qs = require('querystring');

const styles = theme => ({
    root: {
        borderRadius: 10,
        padding: theme.spacing(2),
        margin: theme.spacing(2,0),
        boxShadow:  "0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #eee",
        background: "#F4F8FA"
    },
    margin: {
        margin: theme.spacing(3, 0)
    },
    favorite: {
        cursor: 'pointer'
    }
});

class GeneratedTweet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tweet: props.tweet,
            copied: false,
            favorite: false,
        }
    }

    toggleFavorite = () => {
        this.setState({
            favorite: !this.state.favorite
        })
    }

    render() {
        const { classes } = this.props;

        return <Grid item xs={12} className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body1" align="left">
                        {this.state.tweet}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Grid container spacing={3} alignContent="center" alignItems="center">
                        <Grid item xs={6}>
                            {this.state.copied ?
                            <CheckOutlinedIcon style={{color: 'green'}}/>
                            :
                            <CopyToClipboard text={this.state.value}
                                onCopy={() => this.setState({ copied: true })}>
                                <FileCopyIcon />
                            </CopyToClipboard>
                            }
                            
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.favorite} onClick={this.toggleFavorite}>
                                {this.state.favorite ?
                                    <FavoriteIcon style={{ color: 'red' }} />
                                    :
                                    <FavoriteBorderIcon style={{ color: 'darkgray', '&:hover': { color: 'red' } }} />
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }
}

export default withRouter(withStyles(styles)(GeneratedTweet));