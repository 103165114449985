import React from 'react';
import {
    Container,
    TextField,
    Grid,
    Typography,
    Button,
    Paper, Divider, CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import GeneratedTweet from './GeneratedTweet';
const qs = require('querystring');
const axios = require('axios');

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - 55px)`,
        background: "#ECF0F3"
    },
    margin: {
        margin: theme.spacing(8, 0)
    },
    disclaimer: {
        marginTop: 'auto'
    }
});

const editFields = [
    {
        name: "topic",
        displayLabel: "Topic",
        placeholder: "Example: The consequences of a fully-remote future"
    },
]

class Create extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            account: {},
            edit: true,
            waiting: false,
            tweets: [],
            topic: ""
        }
    }

    handleChange = (e) => {
        if (this.state.topic.length > 256) {
            return;
        }
        this.setState({
            [e.currentTarget.id]: e.currentTarget.value
        });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let topic = this.state.topic;
        if (!this.state.topic || this.state.topic === "") {
            this.setState({
                topic: "The consequences of a fully remote future"
            });
            topic = "The consequences of a fully remote future";
            
        } else {
            e.preventDefault();
        }

        this.setState({
            waiting: true
        });

        const params = qs.parse(this.props.location.search);
        console.log({ params });
        const response = await axios.post("https://personal-tweet-bot.uc.r.appspot.com/generate-tweet", {
        // const response = await axios.post("http://localhost:3000/generate-tweet", {
            topic: topic,
            open_ai_token: params['?token'] || params['token']
        });
        if (response.status === 401) {
            this.setState({error: "Unauthenticated (or maybe not using token?)"})
        } else if (response.status === 500) {
            this.setState({error: "Server Error, try again later"})
        }
        this.setState({
            error: false,
            waiting: false,
            tweets: response.data
        });
    }

    render() {
        const { classes } = this.props;

        return <div className={classes.root}>
            <Container maxWidth="sm" style={{marginBottom: 32}}>
                <Grid component="form" noValidate onSubmit={this.handleSubmit} container spacing={3} className={classes.margin} align="left">
                    <Grid item xs={12}>
                        <Typography variant="h3" paragraph>
                            Compose:
                        </Typography>
                        <Typography variant="body1">
                            Give us a topic to tweet about and we will return you 5 generated tweets about that topic!
                        </Typography>
                    </Grid>
                    {editFields.map(field => {
                        return <Grid item xs={12}>
                            <Typography variant="body1" align="left">
                                {field.displayLabel}
                            </Typography>
                            {
                                this.state.edit ?
                                    <TextField
                                        placeholder={field.placeholder}
                                        id={field.name}
                                        fullWidth
                                        required
                                        variant="outlined"
                                        value={this.state[field.name]}
                                        onChange={this.handleChange} />
                                    :
                                    <Typography variant="h6" align="left">{this.state[field.name]}</Typography>

                            }
                        </Grid>
                    })}
                    <Grid item xs={12} align="center">
                        <Button type="submit" variant="contained" color="secondary">GENERATE</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justify="center" >
                    <Grid item xs={12}>
                    <Typography variant="h5" paragraph align="left">
                            TweetHub.io Results:
                        </Typography>
                    </Grid>
                        
                    {this.state.waiting ?
                        <CircularProgress/>
                    :
                    this.state.tweets.map((tweet) => {
                        return <GeneratedTweet id={tweet} tweet={tweet}/>
                    })
                    }
                </Grid>
                
            </Container>
            <Container maxWidth="sm" className={classes.disclaimer} >
            <Grid container spacing={3} justify="center" >
                    <Grid item xs={12}>
                        <Divider />
                        <Typography variant="body2" style={{color: "darkgray", marginTop: 8}}>
                            All Tweets are generated with GPT-3 using OpenAI's API.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" style={{color: "darkgray"}}>
                            This is the free version of <a href="https://tweethub.io">TweetHub.io</a> (5 runs per month). <a href="/subscribe">Subscribe</a> now to get unlimited usage!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" >
                            Created with ❤️ by <a href="https://twitter.com/nikita_jerschow">Nikita Jerschow</a>.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    }
}

export default withRouter(withStyles(styles)(Create));