import React from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmptyRounded';
import SearchIcon from '@material-ui/icons/Search';

const styles = (theme) => ({

});

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <AppBar elevation={0} >
                <Toolbar>
                    <HourglassEmptyIcon color="secondary" style={{ margin: 8, cursor: "pointer" }} onClick={() => window.location = "/"} />
                    <Typography variant="h6" style={{ cursor: "pointer" }} onClick={() => window.location = "/"}>
                        TweetHub
              </Typography>
                    <Button component="a" href="/user" style={{ marginLeft: 'auto', textTransform: 'none' }}>
                        <SearchIcon style={{ color: 'white' }} fontSize="small" /> <Typography style={{ color: 'white', marginLeft: 8 }}>Search</Typography>
                    </Button>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withStyles(styles)(Navbar);
