import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-177828211-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const firebaseConfig = {
  apiKey: "AIzaSyDIJUoGkoA52p-u35xn58ro6WyRgJXDwkY",
  authDomain: "personal-tweet-bot.firebaseapp.com",
  databaseURL: "https://personal-tweet-bot.firebaseio.com",
  projectId: "personal-tweet-bot",
  storageBucket: "personal-tweet-bot.appspot.com",
  messagingSenderId: "990596952895",
  appId: "1:990596952895:web:b84c63739f49e7b4ce198a",
  measurementId: "G-K21XWD2Y8Z"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
