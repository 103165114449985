import React from 'react';
import logo from './logo.svg';
import './App.css';
import Landing from './components/Landing';
import UserStatus from './components/UserStatus';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Navbar from './components/Navbar';
import Create from './components/Create';
import Subscribe from './components/Subscribe';

const THEME = createMuiTheme({
  palette: {
    primary: {
      main: "#2A313C"
    },
    secondary: {
      main: "#fcb045"
    }
  },
  typography: {
    "fontFamily": "Quicksand",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
  }
});

function App() {
  return (
    <MuiThemeProvider theme={THEME}>
      <div className="App" style={{backgroundColor: '#f1f1f1', minHeight: 'calc(100vh)'}}>
        <div style={THEME.mixins.toolbar} />
        <Navbar />
        <Router>
          <Switch>
            <Route path="/" component={Landing} exact/>
            <Route path="/user" component={Landing} exact/>
            <Route path="/user/:username" component={UserStatus} exact/>
            <Route path="/create" component={Create} exact/>
            <Route path="/subscribe" component={Subscribe} exact />
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
